<template>
  <div class="badges -align-center content-container">
    <p class="-fs8 -mb40">See what our customers and partners are saying about us.</p>
    <div class="-flex-center -flex-wrap -align-center">
      <div 
        class="-mx30 -my10" 
        data-aos="fade-in" 
        data-aos-easing="ease-out-cubic" 
        data-aos-duration="600" 
        :data-aos-delay="inc * 100" 
        v-for="(item, inc) in value" 
        :key="item.url"
      >
        <a :href="item.url" target="_blank">
          <img :src="item.image" />
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: Array,
  },
  computed: {

  },
  watch: {

  },
  mounted() {

  },
}
</script>
<style lang="scss">
  .badges {
    img {
      width: 100%;
      max-width: 180px;
      max-height: 100px;
    }
  }
</style>