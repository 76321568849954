<template>
  <div class="dynamic-page" :key="$route.path">
    <Splash 
      :shadow="splashData && splashData.shadow" 
      class="dynamic-page__splash" 
      :style="{'height': splashData && splashData.height}" 
      :image="splashData && splashData.image ? splash : null"
      :video="splash && splashData.video ? splash : null"
      :opacity="splashData && splashData.opacity"
    >
      <div class="dynamic-page__splash__body" v-if="splashData">
        <div>
          <div class="dynamic-page__splash__body__back" v-if="splashData.back">
            <router-link class="back-btn" :to="splashData.back">
             <Icon size="17px" class="-mr5">icon-park-solid:back</Icon>Back
            </router-link>
          </div>
          <h1 class="dynamic-page__splash__body__title" data-aos="fade-in" data-aos-duration="500" v-html="splashData.title" v-if="splashData.title" />
          <h2 class="dynamic-page__splash__body__tagline" data-aos="fade-in" data-aos-duration="500" data-aos-delay="300" v-if="splashData.tagline" v-html="splashData.tagline" />
          <div class="dynamic-page__splash__body__message" data-aos="fade-in" data-aos-duration="500" v-if="splashData.message" data-aos-delay="600" v-html="splashData.message" />
          <div class="dynamic-page__splash__body__button" data-aos="fade-in" data-aos-easing="cubic" data-aos-duration="500" data-aos-delay="900" v-if="splashData.button">
            <Btn large rounded :route="splashData.route" v-html="splashData.button" />
          </div>
        </div>
        <div class="dynamic-page__splash__body__image" v-if="splashData.productImage">
          <Image :value="splashData.productImage" />
        </div>
      </div>
    </Splash>
    <div class="dynamic-page__body" v-if="data">
      <Section v-for="item in data.sections" :key="data?.meta?.title + item.id" :value="item" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'DynamicPage',
  data() {
    return {
      data: null,
      loading: true,
      splash: null,
    }
  },
  async mounted() {
    await this.getData()
    this.loadScripts()
  },
  watch: {
    async '$route'() {
      window.scrollTo(0, 0)
      await this.getData()
      this.loadScripts()
    }
  },
  computed: {
    splashData() {
      return this.data && this.data.splash
    }
  },
  methods: {
    loadScripts() {
      if (!this.data?.scripts?.length) return
      this.data.scripts.map((val) => {
        const script = document.createElement('script')
        script.setAttribute('type', 'text/javascript')
        script.setAttribute('async', true)
        script.setAttribute('src', val)
        this.$el.appendChild(script)
      })
    },
    setMeta() {
      document.title = this.data.meta.title
      document.head.children.namedItem('keywords').content = this.data.meta.keywords
      document.head.children.namedItem('description').content = this.data.meta.description
    },
    async getData() {
      this.loading = true
      const data = this.$route.meta.data
      this.data = data
      this.setMeta()
      if (!data.splash) {
        this.splash = null
        this.loading = false
        return
      }
      if (data.splash.image) {
        const image = await import(`@/assets${data.splash.image}`)
        this.splash = image.default
      } else if (data.splash.video) {
        const video = await import(`@/assets${data.splash.video}`)
        this.splash = video.default
      }
      this.loading = false
    }
  }
}
</script>
<style lang="scss">
  .dynamic-page {
    position: relative;
    &__splash {
      padding-top: 70px;
      min-height: 110px;
      transition: height .5s ease;
      //border-bottom: 60px solid var(--color-background);
      &__cta {
        //position: absolute;
        background: rgba(#000, .3);
        height: 90px;
        bottom: -45px;
        max-width: 800px;
        width: 100%;
        border-radius: 20px;
        margin: auto;
        padding: 20px;
        //box-shadow: 0px 0px 25px -10px rgba(#000, 0.25);
      }
      &__body {
        width: 100%;
        padding: 20px;
        display: flex;
        text-align: left;
       // align-items: center;
        //flex: 1;
        > div:first-child {
          flex: 1;
          padding-top: 40px;
        }
        &__image {
          max-width: 650px;
          margin-left: 60px;
          img {
            max-width: 100%;
          }
        }
        &__title {
          font-size: 1.75rem;
          text-align: left;
          //width: 100%;
          padding: 0px 0px 5px 0px;
          text-transform: none;
          letter-spacing: 1px;
          margin-bottom: 30px;
          font-weight: 600;
          display: inline-block;
          align-items: center;
          border-bottom: 4px solid var(--color-accent);
          // &:after {
          //   content: '';
          //   width: 20px;
          //   height: 3px;
          //   display: block;
          //   background: var(--color-accent);
          //   margin-left: 12px;
          // }
          // &:before {
          //   content: '';
          //   width: 4px;
          //   height: 35px;
          //   display: inline-block;
          //   background: var(--color-accent);
          //   margin-right: 15px;
          // }
        }
        &__message {
          font-size: 1.5rem;
          //text-shadow: rgba(#000, .8) 1px 1px 0px;
          margin: 30px 0px 30px 0px;
          opacity: .85;
          max-width: 600px;
          //padding-left: 10px;
        }
        &__button {
          margin: 60px 0px;
          //padding-left: 10px;
        }
        &__tagline {
          font-size: 4.5rem;
          letter-spacing: 0px;
          text-align: left;
          line-height: 4.65rem;
          font-weight: 900;
          //text-shadow: rgba(#000, .8) 0px 10px 50px;
          //text-shadow: rgba(#333, .8) 1px 1px 0px;
          small {
            color: var(--color-accent);
            font-weight: 600;
            font-size: 4.5rem;
            margin-bottom: 7px;
            text-transform: none;
            display: block;
          }
        }
        * {
          color: #FFF;
        }
      }
    }

      .back-btn {
        cursor: pointer;
        &:hover {
          color: #FFF !important;
          text-decoration: underline;
        }
      }

    @media(max-width: 1024px) {
      .dynamic-page__splash {
        height: auto !important;
        &__body {
          padding: 0;
        }
      }
    }

    @media(max-width: 600px) {
      .dynamic-page__splash__body__tagline {
        font-size: 3.5rem !important;
      }
    }

  }
</style>
