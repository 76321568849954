<template>
  <div class="faqs">
    <div class="faqs__item" :class="[{'faqs__item--expand': expand.includes(item.title)}]" v-for="item in value" :key="item.title">
      <h3 class="faqs__item__title" @click="toggle(item)">
        <Icon>chevron-right</Icon>
        <span v-html="item.title" />
      </h3>
      <div  class="faqs__item__descr" v-html="item.description" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: Array,
  },
  data() {
    return {
      expand: []
    }
  },
  methods: {
    toggle(faq) {
      if (this.expand.includes(faq.title)) {
        this.expand = this.expand.filter(i => i !== faq.title)
      } else {
        this.expand.push(faq.title)
      }
    }
  }
}
</script>
<style lang="scss">
  .faqs {
    max-width: 1200px;
    margin: auto;
    padding: 0 30px;
    &__item {
      padding: 30px 10px;
      border: none;
	  border-radius: 5px;
	  margin-bottom: 20px;
	  background: #fafafa;
      &:last-child {
        border-bottom: none;
      }
      &--expand {
        .icon {
          transform: rotate(90deg);
        }
        .faqs__item__descr {
          max-height: 500px;
          opacity: 1;
          line-height: normal;
          padding-top: 25px;
        }
      }
      &__title {
        font-weight: 600;
        font-size: 1.3rem;
        display: flex;
        align-items: center;
        cursor: pointer;
        .icon {
          font-size: 24px;
          margin-right: 5px;
          color: var(--color-accent);
        }
        &:hover {
          color: var(--color-highlight);
        }
      }
      &__descr {
        padding: 0 15px 0 25px;
        opacity: 0;
        max-height: 0;
        overflow: hidden;
        transition: all .2s ease;
      }
    }
  }
</style>